<template>
    <div class="mt-4 mx-4" >
		<forget-pwd v-if="show.forgetPwd" @forgetPwdCallback="onForgetPwdCallback"></forget-pwd>
		<div v-if="show.isLogin" id="input">
			<div class="hd-inner" style="height:80px;">
				<div style="margin-top:58px;">
					<img src="images/logo_icbc.png" style="height:48px;">
				</div>
			</div>

			<div class="login-redouter hd-inner">
				<div class="login-redinner hd-height"></div>
			</div>

			<div style="height:50px;"></div>

			<div class="hd-inner">
				<v-row>
					<v-col style="flex-grow: 0;">
						<div >
							<img src="images/photo_new.png" style="background-size: contain;height: 480px;width: 750px;max-height: 500px;">
						</div>
					</v-col>
					<v-vol style="flex-grow: 1;padding: 12px;">
						<div class="lg-box">
							<h1 class="lg-label" >{{ this.$t('login.form.title') }}</h1>
							<div>
								<v-form ref="detailForm" autocomplete="off" @submit.native.prevent>
									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('login.form.email')" style="left: -30px;"
											v-model.trim="form.email"
											@keyup.enter.native="emailKeypress"
											:prepend-inner-icon="'mdi-account-key'"
											:rules="rules.eamilVerify"
											maxlength="100"
										/>
									</div>

									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('login.form.password')"
											v-model="form.password"
											@keyup.enter.native="passwordKeypress"
											:type="passwordEyes?'password':'text'"
											:prepend-inner-icon="'mdi-key-variant'"
											:rules="rules.passwordVerify"
											maxlength="12"
										/>
										<span style="position:absolute;margin-top:-50px;margin-left:150px;" @click="showPasswordEyes">
											<v-icon>{{ passwordEyes?'mdi-eye-off':'mdi-eye' }}</v-icon>
										</span>
									</div>

									<div class='login-form-padding'>
										<div style="display: inline-block;width:210px;">
											<ath-input-text :label="this.$t('login.form.imgCode')"
												v-model.trim="form.imgCode"
												@keyup.native="checkImgCode"
												@keyup.enter.native="imgCodeKeypress"
												:prepend-inner-icon="'mdi-security'"
												:rules="rules.imgCodeVerify"
												autocomplete="off"
												spellcheck="false"
												lang="en"
												maxlength="5"
											/>
										</div>
										<div style="display: inline-block;width:130px;margin-left:12px;">
											<img style="cursor:pointer;width:130px;height:46px;border-radius:10px;margin-top:12px"
												:src="form.imgCodeSrc"
												@click="getImgCode"
											/>
										</div>

									</div>

									<div id="rememberMe" class='login-form-padding' style="font-size:14px;">
										<ath-checkbox style="width:80px;"
											v-model="form.rememberMe"
											:items="rememberMeItems"
										/>
										<div style="margin-top:-24px;">
											<span style="height:50px;float:right;">
												<a @click="forgetPwd">{{ this.$t("login.btn.forgetPwd") }}</a>
											</span>
										</div>
									</div>

									<div class='login-form-padding'>
										<div style="text-align:center;">
											<ath-button style="border-radius: 10px; min-width: 99%;background-color: #c4151c!important;color: white;"
												color="ath.primary"
												:text="this.$t('login.btn.login')"
												@click="login"
											/>
										</div>
									</div>
								</v-form>
							</div>
						</div>
					</v-vol>
				</v-row>

			</div>
		</div>
	</div>
</template>
<script>
import ForgetPwd from '@/views/forgetPwd.vue'
export default {
	name: 'Login',

	components: {
		ForgetPwd
	},

	beforeRouteEnter(to, from , next) {
		next(vm => vm.init());
	},

	data: function() {
		return {
			show: {
				isLogin: true,
				forgetPwd: false
			},
			loginParam: {
				salt: '',
				randomText: '',
				modulus: '',
				alg: '',
				loginFlag: 0,
				publicExponent: '',
				sid: '',
				domain: '',
				environment: ''
			},
			form: {
				email: '',
				password: '',
				imgCode: '',
				imgCodeSrc: '',
				languageCode: 'zh_HK',
				rememberMe: []
			},
			rules: {
				eamilVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkEmailReg(v) || this.$t('login.tips.emailFormatErr')
				],
				passwordVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkPasswordReg(v) || this.$t('login.tips.passwordReg')
				],
				imgCodeVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkImgCodeReg(v) || this.$t('login.tips.inputImgCode')
				]
			},
			passwordEyes: true,
			rememberMeItems: [
				{ label: this.$t('login.btn.rememberMe'), value: '1', disabled: false }
			]

		};
	},

	methods: {
		init(){
			//获取登录所需参数以及是否需要登录验证
			let params = {
				random: new Date()
			};

			this.$loading(this.$t('msg.loading'));
			this.$athlon.request.post('login/getEnvironmentInfo', params, this.$athlon.config.api.requestParamheaders)
			.then((res) => {
				this.$loading(false);
				if(this.dealGlobalResponse(res)) return;
				this.loginParam.salt = res.data.salt;
				this.loginParam.randomText = res.data.randomText;
				this.loginParam.modulus = res.data.modulus;
				this.loginParam.alg = res.data.alg;
				this.loginParam.loginFlag = res.data.loginFlag;
				this.loginParam.publicExponent = res.data.publicExponent;
				this.loginParam.sid = res.data.sid;
				this.loginParam.environment = res.data.environment;
				this.loginParam.domain = res.data.domain;
				if(this.loginParam.loginFlag == 1){
					this.show.isLogin = false;
				}
				if(!this.show.isLogin){
					this.authenticate();
					return;
				}
				this.$athlon.setLocale("zh-HK"); //设置登錄默認语言
				let email = this.$athlon.cookies.get('email');
				if(email && email.length > 0){
					this.form.rememberMe.push("1");
					this.form.email = email;
				}
				this.getImgCode();
			}).catch((e) => {
				this.$loading(false);
				this.$router.push('/error/500');
			});
		},

		authenticate() {
			let params = {
				random: new Date()
			};
			return this.$athlon.request.post('login/staff', params, this.$athlon.config.api.requestParamheaders).then((res) => {
				this.$loading(false);
				if(this.dealGlobalResponse(res)) return;
				if(res.data && res.data.roleData){
					this.$athlon.setRoleList(res.data.roleData);
					this.$athlon.setUserData(res.data, true);
					this.$root.$emit('GLOBAL-authentication', 'success');
					let language = res.data.language;
					language = language.replace('_', '-');
					this.$athlon.setLocale(language); //设置语言
					let jumpUrl = '/home'; //默认跳转着陆页
					if(this.$route.query.page){
						jumpUrl = decodeURIComponent(this.$route.query.page);
					}
					this.$router.push({path: jumpUrl});
				}
			}).catch(() => {
				this.$loading(false);
				this.$router.push('/error/500');
			});

		},

		emailKeypress(){
			//郵箱輸入框enter事件
			let result = this.$refs.detailForm.validate();
			if(!result) return;
			this.login();
		},

		passwordKeypress(){
			//密碼輸入框enter事件
			let result = this.$refs.detailForm.validate();
			if(!result) return;
			this.login();
		},

		showPasswordEyes(){
			if(this.passwordEyes == true){
				this.passwordEyes = false;
			}else{
				this.passwordEyes = true;
			}
		},

		imgCodeKeypress() {
			//圖片驗證碼輸入框enter事件
			let result = this.$refs.detailForm.validate();
			if(!result) return;
			this.login();
		},

		checkEmailReg(value){
			let regular = new RegExp(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkImgCodeReg(value){
			let regular = new RegExp(/[a-zA-Z0-9]{5}/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkImgCode(){
			let value = this.form.imgCode || '';
			let reg = new RegExp(/[^a-zA-Z0-9]/g);
			if(reg.test(value)){
				this.form.imgCode = value.replace(reg,'');
			}
		},

		forgetPwd(){
			//跳轉忘記密碼頁面進行密碼重置
			this.show.forgetPwd = true;
			this.show.isLogin = false;
		},

		onForgetPwdCallback(data){
			//忘記密碼頁跳轉回來
			this.show.forgetPwd = false;
			this.show.isLogin = true;
		},

		getImgCode(){//獲取圖片驗證碼
			this.form.imgCode = '';
			this.form.imgCodeSrc = "login/getImgCode?random=" + new Date() + "&sid=" + this.loginParam.sid;
		},

		checkPasswordReg(value){
			//密碼必須包含大小寫字母和數字三種類型，長度為8-12位
			let regular = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[A-Za-z0-9]{8,12}$/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		login(){//登錄
			let result = this.$refs.detailForm.validate();//form验证结果
			if(!result) return;

			let params = {
				random: new Date(),
				sid: this.loginParam.sid,
				email: this.form.email,
				password: this.encryptForServer(this.form.password),
				imgCode: this.form.imgCode,
				rememberMe: this.form.rememberMe[0]==undefined?'0':'1',
				language: 'zh_HK',
				randomText: this.loginParam.randomText
			}

			this.$loading(this.$t('msg.loading'));
			this.$athlon.request.post('login/signin', params, this.$athlon.config.api.requestParamheaders)
			.then((res) => {
				this.$loading(false);
				if(res.data.retCode && res.data.retCode != '0' && res.data.retCode != '-1'){
					this.tipsMsg(this.$t('login.tips.loginResult.'+res.data.retCode));
					this.getImgCode();
					return;
				}
				if(res.data.emailFlag && res.data.emailFlag != '0'){//邮箱验证码发送异常
					this.tipsMsg(this.$t('login.tips.emailResult.'+res.data.emailFlag));
					return;
				}

				if(res.data.retCode && res.data.retCode == '0'){//正常登錄
					this.inputEmailCode("0");
					return;
				}
				if(res.data.retCode && res.data.retCode == '-1'){//提示修改初始密碼
					this.inputEmailCode("-1");
					return;
				}
			}).catch(() => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});
		},

		encryptForServer(password) {//前端js方式加密密碼

			/* eslint-disable */
			let salt = this.loginParam.salt; // salt 公共盐值，不要写死，从JAVA中传递过来
			let hashText = this.hash(salt + this.hash(password)); // 计算密码的哈希值
			let plainText = this.loginParam.randomText + hashText;

			let rsaKeylen = 2048; // 密钥和密文长度
			setMaxDigits(rsaKeylen/8 + 1);// 外部js文件中的方法

			let modulus = this.loginParam.modulus; // 公钥模值，不要写死，从JAVA中传递过来
			let publicExponent = this.loginParam.publicExponent;

			var rsakey = new RSAKeyPair(publicExponent, publicExponent, modulus, rsaKeylen); // 构造RSA密钥对象,外部js文件中的方法

			var alg = this.loginParam.alg;
			var cipherText = encryptedString(rsakey, plainText, alg).toUpperCase(); // 加密

			/* eslint-disable */
			return cipherText;
		},

		hash(input) {//計算字符串的hash值 sha 256方式
			/* eslint-disable */
			var value = "" + CryptoJS.SHA256(input);
			/* eslint-disable */
			return value.toUpperCase();
		},

		inputEmailCode(toShowTips){
			let self = this;
			this.$dialog.open({
				title: this.$t('login.form.emailCode'),
				maxWidth:'20%',
				//persistent: false,
				component: {
					ctor: inputEmailCode,
					props: {
						data: {
							self: self,
							toShowTips: toShowTips
						}
					}
				},
				footBtn: [
					{eventId: 11, color: 'primary', text: this.$t('login.btn.sendEmail')}
				],
				onaction: (e, data) => {
					if(e.eventId === 12){
						this.getImgCode();
						this.$dialog.close();
						return;
					}

					if(data.inputEmailCode.length == 0 || data.inputEmailCode.length < 5) return;
					this.verifyEmailCode(data.inputEmailCode, toShowTips);
				}
			});
		},

		verifyEmailCode(emailCode, toShowTips){

			let params = {
				random: new Date(),
				sid: this.loginParam.sid,
				email: this.form.email,
				emailCode: emailCode
			}

			this.$loading(this.$t('msg.loading'));
			this.$athlon.request.post('login/verifyEmailCode', params, this.$athlon.config.api.requestParamheaders)
			.then((res) => {
				this.$loading(false);
				if(res.data.retCode == "0"){
					if(toShowTips == "-1"){
						this.tipsOperateResult(this.$t('login.tips.pwdIsInit'));
					}else{
						this.$dialog.close();
						this.authenticate();
					}
				}else{
					let msg = this.$t('login.tips.verifyEmailCodeResult.'+res.data.retCode);
					if(res.data.retCode == "2" || res.data.retCode == "4"){
						this.returnInputCode(msg, toShowTips);
						return;
					}
					this.tipsMsg(msg);
					this.getImgCode();
				}
			}).catch(() => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});
		},

		tipsOperateResult(msg) {
			this.$loading(false);
			this.$dialog.open({
				title: this.$t('athlon.common.hint'),
				maxWidth:'30%',
				persistent: false,
				component: {
					ctor: { template: '<div style="color:red">' + msg + '</div>'}
				},
				footBtn: [
					{eventId: 11, color: 'primary', text: this.$t('athlon.common.confirm') }
				],
				onaction: (e) => {
					this.$dialog.close();
					this.authenticate();
				}
			});
		},

		returnInputCode(msg, toShowTips){
			this.$loading(false);
			this.$dialog.open({
				title: this.$t('athlon.common.hint'),
				maxWidth: '20%',
				persistent: false,
				component: {
					ctor: { template: '<div style="color:red">' + msg + '</div>' }
				},
				footBtn: [
					{ eventId: 11, color: 'primary', icon: 'mdi-check', text: this.$t('athlon.common.confirm') }
				],
				onaction: (e) => {
					this.inputEmailCode(toShowTips);
				}
			});
		}

	}

};

const inputEmailCode = {
	props: ['data'],
	template: `
		<div>
			<v-form autocomplete="off" @submit.native.prevent>
				<span style="color:red;">{{ this.$t("login.tips.sendEmailSuccess") }}</span>
				<ath-input-text style="margin-top: 10px;"
					v-model="inputEmailCode"
					:clearable="false"
					:auto-grow="false"
					:hint="hint"
					@keyup.native="checkEmailCode"
					@keyup.enter.native="emailCodeKeypress"
					:prepend-inner-icon="'mdi-shield-half-full'"
					:rules="rules.inputEmailCode"
					autocomplete="off"
					spellcheck="false"
					lang="en"
					maxlength="5"
				/>
			</v-form>
		</div>
	`,
	data: function () {
		return {
			dModel: {
				...this.data,
			},
			inputEmailCode: '',
			hint: this.$t('login.tips.inputEmailCode'),
			rules: {
				inputEmailCode: [
					v => this.checkNull(v) || this.$t('login.tips.inputEmailCode'),
					v => this.checkEmailCodeReg(v) || this.$t('login.tips.inputEmailCode')
				]
			}
		}
	},
	methods: {
		beforeTriggerAction() {
			return {inputEmailCode: this.inputEmailCode}
		},
		checkNull(value) {
			value = value || ''
			if(value.trim().length == 0 || value.trim().length < 5) return false;
			return true;
		},
		emailCodeKeypress(){
			if(!this.checkNull(this.inputEmailCode)) return;
			this.dModel.self.verifyEmailCode(this.inputEmailCode, this.dModel.toShowTips);
		},
		checkEmailCode(){
			let value = this.inputEmailCode || '';
			let reg = new RegExp(/[^a-zA-Z0-9]/g);
			if(reg.test(value)){
				this.inputEmailCode = value.replace(reg,'');
			}
		},
		checkEmailCodeReg(value){
			let regular = new RegExp(/[a-zA-Z0-9]{5}/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},
	}
}
</script>

<style>
.hd-inner{
	width:1200px;
	height:144px;
	line-height:94px;
	margin:0 auto
}
.login-redouter{
	height:12px;
	background-color: white;
}
.login-redinner{
	height:12px;
	background-color: #c4161c;
}
.lg-box {
	height: 480px;
	line-height: 20px;
}
.login-form-padding {
	text-align: center;
	padding-bottom: 0px;
	padding-left: 36px;
	padding-right: 36px;
}
.lg-label {
	color: #000;
	font-size: 26px;
	height: 44px;
	line-height: 44px;
	margin-top: 40px;
	text-indent: 30px;
	margin-bottom: 20px;
	padding-left: 5px;
}
img {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}
#input .theme--light.v-label {
    color: rgba(0,0,0,.6);
    margin-left: -28px;
    margin-top: -5px;
}
#rememberMe .theme--light.v-label {
    color: rgba(0,0,0,.6);
    margin-left: 0px;
    margin-top: -5px;
}
#rememberMe .v-label {
    font-size: 14px;
}
</style>
