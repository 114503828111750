<template>
    <div class="mt-4 mx-4" id="input">
		<div class="hd-inner" style="height:110px;">
			<div style="margin-top:32px;">
				<img src="images/logo_icbc.png" style="height:48px;">
			</div>
		</div>

		<div class="login-redouter hd-inner">
			<div class="login-redinner hd-height"></div>
		</div>

		<div style="height:50px;"></div>

		<div class="hd-inner">
			<v-row>
				<v-col style="flex-grow: 0;">
					<div >
						<img src="images/photo_new.png" style="background-size: contain;height: 560px;width: 750px;max-height: 560px;">
					</div>
				</v-col>
				<v-vol style="flex-grow: 1;padding: 12px;">
					<div class="lg-box">
						<h1 class="lg-label" >{{ this.$t('forgetPwd.form.title') }}</h1>
						<div>

							<div v-if="show.toCheck">
								<v-form ref="detailForm1" autocomplete="off" @submit.native.prevent>
									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('forgetPwd.form.merId')" style="left: -30px;"
											v-model.trim="form.merId"
											@keyup.enter.native="merIdKeypress"
											:prepend-inner-icon="'mdi-account-settings'"
											:disabled="readonly.merId"
											:rules="rules.merIdVerify"
											maxlength="12"
										/>
									</div>

									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('forgetPwd.form.phone')" style="left: -30px;"
											v-model.trim="form.phone"
											@keyup.enter.native="phoneKeypress"
											:prepend-inner-icon="'mdi-cellphone-android'"
											:disabled="readonly.phone"
											:rules="rules.phoneVerify"
											maxlength="40"
										/>
									</div>

									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('forgetPwd.form.email')" style="left: -30px;"
											v-model.trim="form.email"
											@keyup.enter.native="emailKeypress"
											:prepend-inner-icon="'mdi-account-key'"
											:disabled="readonly.email"
											:rules="rules.eamilVerify"
											maxlength="40"
										/>
									</div>

									<div class='login-form-padding'>
										<div style="display: inline-block;width:210px;">
											<ath-input-text :label="this.$t('forgetPwd.form.imgCode')"
												v-model.trim="form.imgCode"
												@keyup.native="checkImgCode"
												@keyup.enter.native="imgCodeKeypress"
												:prepend-inner-icon="'mdi-security'"
												:disabled="readonly.imgCode"
												:rules="rules.imgCodeVerify"
												autocomplete="off"
												spellcheck="false"
												lang="en"
												maxlength="5"
											/>
										</div>
										<div style="display: inline-block;width:130px;margin-left:12px;">
											<img style="cursor:pointer;width:130px;height:46px;border-radius:10px;margin-top:12px"
												:src="form.imgCodeSrc"
												@click="getImgCode"
											/>
										</div>
									</div>
								</v-form>

								<v-form ref="detailForm2" autocomplete="off" @submit.native.prevent>
									<div class='login-form-padding'>
										<div style="display: inline-block;width:210px;">
											<ath-input-text :label="this.$t('forgetPwd.form.emailCode')"
												v-model.trim="form.emailCode"
												@keyup.native="checkEmailCode"
												@keyup.enter.native="emailCodeKeypress"
												:prepend-inner-icon="'mdi-shield-half-full'"
												:rules="rules.emailCodeVerify"
												autocomplete="off"
												spellcheck="false"
												lang="en"
												maxlength="5"
											/>
										</div>
										<div style="display: inline-block;width:130px;margin-left:12px;">
											<ath-button style="border-radius:10px;width:130px;background-color: rgb(196, 21, 28) !important;color: white;margin:15px 15px 40px 0px;"
												color="ath.primary"
												:text="sendEmailText"
                                        		:disabled="readonly.sendEmail"
												@click="sendEmailCode"
											/>
										</div>
									</div>

									<div class='login-form-padding'>
										<div style="text-align:center;">
											<ath-button style="border-radius:10px;min-width:36%;background-color: #c4151c!important;color: white;margin-right:20px;"
												color="ath.primary"
												:text="this.$t('forgetPwd.btn.next')"
												@click="nextBtn"
											/>
											<ath-button style="border-radius:10px;min-width:40%;background-color: #c4151c!important;color: white;"
												color="ath.primary"
												:text="this.$t('forgetPwd.btn.cancel')"
												@click="cancelBtn"
											/>
										</div>
									</div>
								</v-form>
							</div>

							<div v-if="show.resetPwd">
								<v-form autocomplete="off" @submit.native.prevent>
									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('forgetPwd.form.username')"
											v-model.trim="form.username"
											:prepend-inner-icon="'mdi-account-circle'"
											disabled="false"
											:rules="rules.usernameVerify"
											maxlength="40"
										/>
									</div>

									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('forgetPwd.form.newPassword')"
											v-model="form.newPassword"
											@keyup.enter.native="newPasswordKeypress"
											:type="eyes.newPassword?'password':'text'"
											:prepend-inner-icon="'mdi-key-variant'"
											ref="newPasswordRef"
											:rules="rules.newPasswordVerify"
											maxlength="12"
										/>
										<span style="position:absolute;margin-top:-50px;margin-left:150px;" @click="showNewPassword">
											<v-icon>{{ eyes.newPassword?'mdi-eye-off':'mdi-eye' }}</v-icon>
										</span>
									</div>

									<div class='login-form-padding'>
										<ath-input-text :label="this.$t('forgetPwd.form.confirmPassword')"
											v-model="form.confirmPassword"
											@keyup.enter.native="confirmPasswordKeypress"
											:type="eyes.confirmPassword?'password':'text'"
											:prepend-inner-icon="'mdi-key-variant'"
											ref="confirmPasswordRef"
											:rules="rules.confirmPasswordVerify"
											maxlength="12"
										/>
										<span style="position:absolute;margin-top:-50px;margin-left:150px;" @click="showConfirmPassword">
											<v-icon>{{ eyes.confirmPassword?'mdi-eye-off':'mdi-eye' }}</v-icon>
										</span>
									</div>

									<div class='login-form-padding'>
										<div style="text-align:center;padding-top:36px;">
											<ath-button style="border-radius: 10px; min-width: 40%;background-color: #c4151c!important;color: white;margin-right:20px;"
												color="ath.primary"
												:text="this.$t('forgetPwd.btn.reset')"
												@click="reset"
											/>
											<ath-button style="border-radius:10px;min-width:40%;background-color: #c4151c!important;color: white;"
												color="ath.primary"
												:text="this.$t('forgetPwd.btn.cancel')"
												@click="cancelBtn"
											/>
										</div>
									</div>
								</v-form>
							</div>

						</div>
					</div>
				</v-vol>
			</v-row>

		</div>

	</div>
</template>
<script>
export default {
	name: 'ForgetPwd',

	data: function() {
		return {
			show: {
				resetPwd: false,
				toCheck: true
			},
			loginParam: {
				salt: '',
				randomText: '',
				modulus: '',
				alg: '',
				publicExponent: '',
				sid: ''
			},
			form: {
				merId: '',
				phone: '',
				email: '',
				imgCode: '',
				imgCodeSrc: '',
				emailCode: '',
				language: 'zh_HK',
				username: '',
				newPassword: '',
				confirmPassword: '',
				sendEmailText: this.$t('forgetPwd.btn.sendEmail')
			},
			readonly: {
				merId: false,
				phone: false,
				email: false,
				imgCode: false,
				sendEmail: false
			},
			rules: {
				merIdVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkMerIdReg(v) || this.$t('forgetPwd.tips.inputMerId')
				],
				phoneVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkPhoneReg(v) || this.$t('forgetPwd.tips.inputPhone')
				],
				eamilVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkEmailReg(v) || this.$t('forgetPwd.tips.emailFormatErr')
				],
				imgCodeVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkImgCodeReg(v) || this.$t('forgetPwd.tips.inputImgCode')
				],
				emailCodeVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkEmailCodeReg(v) || this.$t('forgetPwd.tips.inputEmailCode')
				],
				usernameVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField')
				],
				newPasswordVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkPasswordReg(v) || this.$t('forgetPwd.tips.passwordReg')
				],
				confirmPasswordVerify: [
					v => !!v || this.$t('msg.pleaseEnterRequiredField'),
					v => this.checkPasswordReg(v) || this.$t('forgetPwd.tips.passwordReg'),
					v => this.checkPasswordIsEqual(v) || this.$t('forgetPwd.tips.passwordNotEqual')
				],
			},
			eyes: {
				newPassword: true,
				confirmPassword: true,
			},
			countdown: 120,
			timeKeepingType: "none"

		};
	},

	created: function(){
		this.init();
	},

	computed: {
		sendEmailText(){
			return this.form.sendEmailText;
		}
	},

	methods: {
		init(){
			this.$loading(this.$t('msg.loading'));
			let params = {
				random: new Date()
			};
			this.$athlon.request.post('login/getEnvironmentInfo', params, this.$athlon.config.api.requestParamheaders).then((res) => {
				this.$loading(false);
				if(this.dealGlobalResponse(res)) return;
				this.loginParam.salt = res.data.salt;
				this.loginParam.randomText = res.data.randomText;
				this.loginParam.modulus = res.data.modulus;
				this.loginParam.alg = res.data.alg;
				this.loginParam.publicExponent = res.data.publicExponent;
				this.loginParam.sid = res.data.sid;
				this.getImgCode();
			}).catch(() => {
				this.$loading(false);
				this.$router.push('/error/500');
			});
		},

		merIdKeypress(){
			//商戶編號輸入框enter事件
			let result1 = this.$refs.detailForm1.validate();
			if(!result1) return;
			this.sendEmailCode();//發送郵箱驗證碼
		},

		phoneKeypress(){
			//手機號輸入框enter事件
			let result1 = this.$refs.detailForm1.validate();
			if(!result1) return;
			this.sendEmailCode();//發送郵箱驗證碼
		},

		emailKeypress(){
			//郵箱輸入框enter事件
			let result1 = this.$refs.detailForm1.validate();
			if(!result1) return;
			this.sendEmailCode();//發送郵箱驗證碼
		},

		checkImgCode(){
			let value = this.form.imgCode || '';
			let reg = new RegExp(/[^a-zA-Z0-9]/g);
			if(reg.test(value)){
				this.form.imgCode = value.replace(reg,'');
			}
		},

		imgCodeKeypress() {
			//圖片驗證碼輸入框enter事件
			let result1 = this.$refs.detailForm1.validate();
			if(!result1) return;
			this.sendEmailCode();//發送郵箱驗證碼
		},

		getImgCode(){//獲取圖片驗證碼
			if(this.readonly.sendEmail) return;
			this.form.imgCodeSrc = "login/getImgCode?random=" + new Date() + "&sid=" + this.loginParam.sid;
		},

		sendEmailCode(){//發送電郵驗證碼

			if(this.readonly.sendEmail) return;//防重複點擊發送按鈕
			this.readonly.sendEmail = true;

			let result1 = this.$refs.detailForm1.validate();
			if(!result1){
				this.readonly.sendEmail = false;
				return;
			}
			this.readonly.merId = true;
			this.readonly.phone = true;
			this.readonly.email = true;
			this.readonly.imgCode = true;

			let params = {
				random: new Date(),
				sid: this.loginParam.sid,
				merId: this.form.merId,
				phone: this.form.phone,
				email: this.form.email,
				imgCode: this.form.imgCode,
				language: this.form.language
			}

			this.$loading(this.$t('msg.loading'));
			this.$athlon.request.post('forgetPwd/sendEmailCode', params, this.$athlon.config.api.requestParamheaders)
			.then((res) => {
				this.$loading(false);
				if(res.data.retCode && res.data.retCode != '0'){
					this.tipsMsg(this.$t('forgetPwd.tips.sendEamilResult.'+res.data.retCode));
					this.readonly.sendEmail = false;
					this.readonly.merId = false;
					this.readonly.phone = false;
					this.readonly.email = false;
					this.readonly.imgCode = false;
					return;
				}
				if(res.data.emailFlag && res.data.emailFlag != '0'){//邮箱验证码发送异常
					this.tipsMsg(this.$t('login.tips.emailResult.'+res.data.emailFlag));
					this.readonly.sendEmail = false;
					this.readonly.merId = false;
					this.readonly.phone = false;
					this.readonly.email = false;
					this.readonly.imgCode = false;
					return;
				}
				this.timeKeeping();
				this.$snackbar(this.$t('forgetPwd.tips.sendEamilResult.success'));
			}).catch(() => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});
		},

		checkEmailCode(){
			let value = this.form.emailCode || '';
			let reg = new RegExp(/[^a-zA-Z0-9]/g);
			if(reg.test(value)){
				this.form.emailCode = value.replace(reg,'');
			}
		},

		emailCodeKeypress(){
			//電郵驗證碼輸入框enter事件
			let result1 = this.$refs.detailForm1.validate();
			if(!result1) return;
			let result2 = this.$refs.detailForm2.validate();
			if(!result2) return;
			this.nextBtn();//跳轉重置密碼頁面
		},

		timeKeeping(){
			var self = this;
			var timeout;
			clearTimeout(timeout);
			if(this.countdown == 0 || this.countdown < 0){
				this.countdown = 120;
				this.form.sendEmailText = this.$t('forgetPwd.btn.sendEmail');
				this.readonly.sendEmail = false;
				this.readonly.merId = false;
				this.readonly.phone = false;
				this.readonly.email = false;
				this.readonly.imgCode = false;
				if(this.timeKeepingType == "toGetImgCode"){
					this.timeKeepingType = "none";
					this.form.imgCode = "";
					this.getImgCode();
				}
				return;
			} else {
				this.form.sendEmailText = this.$t('forgetPwd.btn.reSendEmail')+"("+this.countdown+"s)";
				this.countdown = this.countdown - 1;
			}
			timeout = setTimeout(function(){self.timeKeeping("none");},1000);
		},

		nextBtn(){
			//驗證郵箱驗證碼，進入到下一步
			let result1 = this.$refs.detailForm1.validate();
			if(!result1) return;
			let result2 = this.$refs.detailForm2.validate();
			if(!result2) return;

			let params = {
				random: new Date(),
				sid: this.loginParam.sid,
				merId: this.form.merId,
				phone: this.form.phone,
				email: this.form.email,
				imgCode: this.form.imgCode,
				emailCode: this.form.emailCode
			}

			this.$loading(this.$t('msg.loading'));
			this.$athlon.request.post('forgetPwd/checkEmailCode', params, this.$athlon.config.api.requestParamheaders)
			.then((res) => {
				this.$loading(false);
				if(res.data.retCode && res.data.retCode != '0'){
					this.tipsMsg(this.$t('forgetPwd.tips.checkEmailCodeResult.'+res.data.retCode));
					this.countdown = -1;
					this.timeKeepingType = "toGetImgCode";
					return;
				}
				if(res.data.retCode && res.data.retCode == '0'){
					this.form.username = res.data.username;
					this.show.resetPwd = true;
					this.show.toCheck = false;
					return;
				}
			}).catch(() => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});

		},

		cancelBtn(){
			this.$emit('forgetPwdCallback', "toLogin");
		},

		showNewPassword(){
			if(this.eyes.newPassword == true){
				this.eyes.newPassword = false;
			}else{
				this.eyes.newPassword = true;
			}
		},

		showConfirmPassword(){
			if(this.eyes.confirmPassword == true){
				this.eyes.confirmPassword = false;
			}else{
				this.eyes.confirmPassword = true;
			}
		},

		checkMerIdReg(value){
			let regular = new RegExp(/^\d{12}$/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkPhoneReg(value){
			let regular = new RegExp(/^\d{4,40}$/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkEmailReg(value){
			let regular = new RegExp(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkImgCodeReg(value){
			let regular = new RegExp(/^[a-zA-Z0-9]{5}$/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkEmailCodeReg(value){
			let regular = new RegExp(/^[a-zA-Z0-9]{5}$/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkPasswordReg(value){
			let regular = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[A-Za-z0-9]{8,12}$/g);
			if(!regular.test(value)){
				return false;
			}
			return true;
		},

		checkPasswordIsEqual(value){
			if(value !== this.form.newPassword){
				return false;
			}
			return true;
		},

		newPasswordKeypress(){
			//新密碼輸入框enter事件
			this.reset();
		},

		confirmPasswordKeypress(){
			//確認密碼輸入框enter事件
			this.reset();
		},

		reset(){//密碼重置

			if(!this.checkPasswordReg(this.form.newPassword)){
				this.tipsMsg(this.$t('forgetPwd.tips.newPasswordReg'));
				return;
			}
			if(!this.checkPasswordReg(this.form.confirmPassword)){
				this.tipsMsg(this.$t('forgetPwd.tips.confirmPasswordReg'));
				return;
			}
			if(!this.checkPasswordIsEqual(this.form.confirmPassword)){
				this.tipsMsg(this.$t('forgetPwd.tips.passwordNotEqual'));
				return;
			}

			let params = {
				random: new Date(),
				sid: this.loginParam.sid,
				email: this.form.email,
				newPassword: this.encryptForServer(this.form.newPassword),
				confirmPassword: this.encryptForServer(this.form.confirmPassword),
				randomText: this.loginParam.randomText
			}

			this.$loading(this.$t('msg.loading'));
			this.$athlon.request.post('forgetPwd/reset', params, this.$athlon.config.api.requestParamheaders)
			.then((res) => {
				this.$loading(false);
				if(res.data.retCode && res.data.retCode != '0'){
					this.tipsMsg(this.$t('forgetPwd.tips.resetResult.'+res.data.retCode));
					return;
				}
				if(res.data.retCode && res.data.retCode == '0'){
					this.$emit('forgetPwdCallback', "toLogin");
					this.tipsMsg(this.$t('forgetPwd.tips.resetResult.'+res.data.retCode));
					return;
				}
			}).catch(() => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});
		},

		encryptForServer(password) {//前端js方式加密密碼

			/* eslint-disable */
			let salt = this.loginParam.salt; // salt 公共盐值，不要写死，从JAVA中传递过来
			let hashText = this.hash(salt + this.hash(password)); // 计算密码的哈希值
			let plainText = this.loginParam.randomText + hashText;

			let rsaKeylen = 2048; // 密钥和密文长度
			setMaxDigits(rsaKeylen/8 + 1);// 外部js文件中的方法

			let modulus = this.loginParam.modulus; // 公钥模值，不要写死，从JAVA中传递过来
			let publicExponent = this.loginParam.publicExponent;

			var rsakey = new RSAKeyPair(publicExponent, publicExponent, modulus, rsaKeylen); // 构造RSA密钥对象,外部js文件中的方法

			var alg = this.loginParam.alg;
			var cipherText = encryptedString(rsakey, plainText, alg).toUpperCase(); // 加密

			/* eslint-disable */
			return cipherText;
		},

		hash(input) {//計算字符串的hash值 sha 256方式
			/* eslint-disable */
			var value = "" + CryptoJS.SHA256(input);
			/* eslint-disable */
			return value.toUpperCase();
		}

	}

};
</script>

<style>
.hd-inner{
	width:1200px;
	height:144px;
	line-height:94px;
	margin:0 auto
}
.login-redouter{
	height:12px;
	background-color: white;
}
.login-redinner{
	height:12px;
	background-color: #c4161c;
}
.lg-box {
	height: 480px;
	line-height: 20px;
}
.login-form-padding {
	text-align: center;
	padding-bottom: 0px;
	padding-left: 36px;
	padding-right: 36px;
}
.lg-label {
	color: #000;
	font-size: 26px;
	height: 44px;
	line-height: 44px;
	margin-top: 10px;
	text-indent: 30px;
	margin-bottom: 20px;
	padding-left: 5px;
}
img {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}
#input .theme--light.v-label {
    color: rgba(0,0,0,.6);
    margin-left: -28px;
    margin-top: -5px;
}
</style>
